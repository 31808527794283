.loadingDot{
animation: blink .7s;
animation-iteration-count: infinite;
animation-direction: forwards;
}
@keyframes blink {
    0%{
        opacity: 0;
    }
    25%{

        opacity: .3;
    }
    50%{

        opacity: 1;
    }
    75%{

        opacity: .5;
    }
    100%{
        opacity: 0;
    }
    
}