.mobileOptionBar{
    animation: mobileNavbarIn .4s;
}

@keyframes mobileNavbarIn {
    from{
        transform: translateX(200px);
    }
    to{
        transform: translateX(0px);
    }
}
