::-webkit-scrollbar {
    width: 1em;
    background-color: rgb(172, 171, 171);
}

::-webkit-scrollbar-thumb {
    /* background-image: linear-gradient(to bottom, rgb(82, 82, 82) , rgb(240, 240, 240)); */
    background-color: #fff;
    border-radius: 100vw;
}

.myContainer {
    margin-left: 15%;
    margin-right: 15%;
}