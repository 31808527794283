 #downloadArrow {
     animation: moveInTop 1s ease-out;
     animation-iteration-count: infinite;
 }

 @keyframes moveInTop {
     from {
         transform: translateY(-100%)
     }

     to {
         transform: translateY(200%)
     }

 }

 #abc {
     opacity: .4;
     transition: all .5s;
 }

 #abc:hover {
     opacity: 1;
 }