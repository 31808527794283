.heroBg {
    /* background-image: linear-gradient(to right, rgb(99, 138, 201) ,rgba(0, 0, 0, 0.322)) ; */
    /* background-color: #729ee0; */
}

.letsTalkArrow {
    transition: all .5s;
}

.letsTalkText:hover .letsTalkArrow {
    transform: translateX(10px);
}