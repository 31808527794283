
.tooltip{
    display: none;
    animation: tooltip .2s ease-in;
}
.emailTextDiv:hover .tooltip{
    display: block;
}

.myContainer{
    margin-left:15%;
    margin-right:15%;
}

@keyframes tooltip {
    from{
        opacity: 0;
    }
    to{
        opacity: .4;
    }
    
}

@keyframes loadingDot {
    0%{
        
    }
    50%{

    }
    100%{

    }
    
}
