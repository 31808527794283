
.active{
    animation: moveInBottom .4s ease-in-out;
}
.projectBottom {
    transition: all .5s;

}
.projectBottom:hover {
    transform: translateY(40px);
}

.detailsBtn{
    animation: detailsBtnOut .4s ;
    animation-fill-mode:forwards;
}

.projectBottom:hover .detailsBtn{
    animation: detailsBtn .4s;

}
.projectBottom:hover .imgContainer, .projectImg{
    height: 30vh;
    display: block;
}
.projectImg{
    /* display: none; */
    opacity: 0;
    transition: all .8s;
    transition-delay: .5s;
}
.projectBottom:hover  .projectImg{
    opacity: 1;

}

.imgContainer{
    height: 0;
    transition: all 1s;

}

.projectBottom:hover .sidebar{
    animation: sidebarOut .4s ;
    animation-fill-mode:forwards;
}
.sidebar{
    animation: sidebarIn .7s ease-in ;
    animation-fill-mode:forwards;
    
}

@keyframes moveInBottom {
    from{
        transform: translateY(100px);
        opacity: 0;
    }
    to{
        transform: translateY(0);
        opacity: 1;

    }
}

@keyframes sidebarOut {
    0%{
        transform: translateX(0px);
    }
    100%{
        transform: translateX(200%);
    }
    
}
@keyframes sidebarIn {
    0%{
        transform: translateX(200%);
    }
    
    100%{
        transform: translateX(0px);
    }
}
@keyframes detailsBtn {
    0%{
        transform: translateY(200px);
    }
    100%{
        transform: translateY(0);
    }
}
@keyframes detailsBtnOut {
    0%{
        transform: translateY(0px);
    }
    100%{
        transform: translateY(80px);
    }
}
@keyframes imgOpacity {
    0%{
        opactity:0;
    }
    100%{
        opactity:1;
    }
}

